<template>
  <div class="field">
    <label class="label">Enter Product ID</label>
    <input
      type="text"
      name="textinput"
      @input="inputChange"
      v-model="inputVal"
      class="input"
      :placeholder="startPid" />
  </div>
  <transition name="fade" mode="out-in">
    <div class="suggestionlist" v-if="pidSuggestions.length">
      <div class="panel">
        <div
          class="sugglistitem"
          v-for="(item, index) in pidSuggestions"
          :key="index"
          @click="suggestionClick(item)">
          {{ item }}
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref } from 'vue';
import Api from '../apirequests';
export default {
  //after 3 chars - lookup and present suggestions
  //on click suggestion emit back to parent
  name: 'PidSelector',
  props: ['startPid'],
  emits: ['pidSelected', 'apiError', 'clearData'],
  setup(props, context) {
    const inputVal = ref('');
    const pidSuggestions = ref([]);

    const inputChange = ref(() => {
      //clearData in parent
      context.emit('clearData');
      if (inputVal.value.length >= 3) {
        //run query
        Api.pidList(inputVal.value)
          .then((response) => {
            const uniquePids = formatSearchPidList(response.data);
            if (uniquePids.length === 0) {
              pidSuggestions.value = ['Product Not Found'];
            } else {
              pidSuggestions.value = uniquePids;
            }
          })
          .catch((err) => {
            context.emit('apiError', err.message);
          });
      } else {
        //clear the suggestions
        pidSuggestions.value = [];
      }
    });

    const suggestionClick = (pid) => {
      inputVal.value = pid;
      pidSuggestions.value = [];
      context.emit('pidSelected', pid);
    };

    return { inputVal, inputChange, pidSuggestions, suggestionClick };
  },
};

const formatSearchPidList = (listData) => {
  //is array of objs with one prop 'item_no' - make simple array of pids
  const extracted = listData.map((pidOb) => {
    return pidOb.item_no;
  });
  //as it is full list of product ids for each image, will contain dupes - remove
  const uniquePids = extracted.filter((val, index, all) => {
    //if the first indexof this value is not this index - then this is a dupe
    return all.indexOf(val) === index;
  });
  return uniquePids;
};
</script>

<style lang="scss" scoped>
@import '../sass/_variables.scss';

.field {
  margin-bottom: 0.2em;
}

.suggestionlist {
  position: relative;

  .panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
    padding: 2px 0;

    .sugglistitem {
      cursor: pointer;
      background-color: $grey-lighter;
      margin: 0 2px 1px 2px;
      padding: 0.5em;

      &:last-of-type {
        margin-bottom: 0;
      }

      &:hover {
        background-color: $primary;
        color: $white-ter;
      }
    }
  }
}
</style>
