<template>
  <div>
    <div class="msg message is-info">
      <div class="message-header">Note</div>
      <div class="message-body is-size-5 has-text-weight-bold">The format of the XLSX uploaded must be in the standard "Bible" format.</div>
    </div>
    <div class="msg message is-link">
      <div class="message-header">Note</div>
      <div class="message-body is-size-5 has-text-weight-bold">It can take ~5 minutes to complete the upload and saving of Bible data.</div>
    </div>
    <div class="title is-6 has-text-grey mt-6">Bible Upload</div>
    <div class="inputContainer">
      <span>Select the Bible XLSX:</span>
      <label class="file-label">
        <input class="file-input" type="file" name="" @change="fileSelected($event)" />
        <span class="file-cta">
          <span class="file-icon">
            <i class="fas fa-upload"></i>
          </span>
          <span class="file-label">
            Choose…
          </span>
        </span>
      </label>
    </div>
    <div class="buttonContainer">
      <button class="button is-warning is-medium disabled" :disabled="!fileReady" @click="upload">Upload</button>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import Api from '../apirequests';
import { useStore } from 'vuex';
import hae from '../includes/HandleApiError';
import { useRouter } from 'vue-router';
export default {
  name: 'AdminBibleUpdate',
  setup() {
    const pollDelaySecs = 20;
    const pollFailAfterMin = 10;
    const store = useStore();
    const router = useRouter();
    const formData = new FormData();
    const fileReady = ref(false);

    const fileSelected = ref((event) => {
      fileReady.value = false;
      if (event.target.files.length && event.target.files[0].name.search(/.+\.xlsx$/) != -1) {
        formData.delete('biblexlsx');
        formData.append('biblexlsx', event.target.files[0]);
        fileReady.value = true;
      } else {
        event.target.value = '';
        store.commit('setMsg', {
          header: 'Notice',
          body: 'Invalid File Type<br><br>Must be XLSX',
        });
      }
    });

    const upload = () => {
      if (fileReady.value) {
        store.commit('setMsg', 'WORKING');
        Api.uploadBible(formData)
          .then(() => {
            pollCompletion();
          })
          .catch((err) => {
            hae(store, router, err.message);
          });
      }
    };

    async function pollCompletion() {
      const maxPolls = (pollFailAfterMin * 60) / pollDelaySecs;
      let pollingStatus = null;
      let pollCount = 0;
      while (pollingStatus != 201 && pollCount < maxPolls) {
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, pollDelaySecs * 1000);
        });

        try {
          const response = await Api.pollCompleted();
          pollCount++;
          if (response) {
            pollingStatus = response.status;
            if (pollingStatus == 201) {
              store.commit('setMsg', {
                header: 'Done',
                body: `The new Bible Data is in place`,
              });
            }
          } else {
            throw 'The polling API method did not return a response';
          }
        } catch (err) {
          hae(store, router, err.message);
        }
      }
      if (pollingStatus != 201) {
        //exceeding number of polls
        hae(store, router, 'Saving the Bible data took too long to complete');
      }
    }

    return { fileReady, fileSelected, upload };
  },
};
</script>

<style lang="scss" scoped>
.message-body {
  text-align: center;
}

.inputContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  label {
    margin-left: 1.5em;
  }
}

.buttonContainer {
  text-align: center;
  margin-top: 3em;
}
</style>
