import store from './store/index';
import axios from 'axios';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Content-Type'] = 'application/json';

axios.interceptors.request.use(
    function (axiosRequest) {
        if (axiosRequest.url == '/api/login') return axiosRequest;
        if ((axiosRequest.url == '/api/libsearch' && store.getters.getHasToken) || store.getters.getIsAdmin) {
            axiosRequest.headers.common.Authorization = 'Bearer ' + localStorage.getItem('iethis_token');
            return axiosRequest;
        }
        //else - simulate an unauth response
        return Promise.reject('401');
    },
    function (err) {
        return Promise.reject(err);
    },
);

export default class Api {
    constructor() {}

    /**
     * Send request to login
     * Did this one old style with explicit promise
     * @param {object} credentials
     */
    static attemptLogin(credentials) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/login`, credentials, {
                    validateStatus: (status) => status < 500,
                })
                .then((response) => {
                    if (response != 'undefined') {
                        switch (response.status) {
                            case 200:
                                resolve(response.data);
                                break;
                            default:
                                reject(response.status);
                        }
                    } else {
                        reject(0);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    /**
     * Will request to return array of objects containing pid: <pid>
     * Did this one with async await in try catch
     * @param {string} pid
     */
    static async pidList(pid) {
        try {
            const response = await axios.post(
                `/api/pidhint`,
                { pid },
                {
                    validateStatus: (status) => status < 500,
                },
            );
            if (response) {
                switch (response.status) {
                    case 200:
                        return response;
                    case 401:
                        throw response.status;
                    default:
                        throw response.status;
                }
            } else {
                throw 0;
            }
        } catch (err) {
            throw new Error(err);
        }
    }

    static async libSearch(loadArgs) {
        try {
            const response = await axios.post(`/api/libsearch`, loadArgs, {
                validateStatus: (status) => status < 500,
            });
            if (response) {
                switch (response.status) {
                    case 200:
                        return response;
                    case 401:
                        throw response.status;
                    default:
                        throw response.status;
                }
            } else {
                throw 0;
            }
        } catch (err) {
            throw new Error(err);
        }
    }

    static async bibleData(pid) {
        try {
            const response = await axios.post(
                '/api/bibledata',
                { pid },
                {
                    validateStatus: (status) => status < 500,
                },
            );
            if (response) {
                switch (response.status) {
                    case 200:
                        return response;
                    case 401:
                        throw response.status;
                    default:
                        throw response.status;
                }
            } else {
                throw 0;
            }
        } catch (err) {
            throw new Error(err);
        }
    }

    static async dataForImage(basename) {
        try {
            const response = await axios.post(
                '/api/dataforimage',
                { basename },
                {
                    validateStatus: (status) => status < 500,
                },
            );
            if (response) {
                switch (response.status) {
                    case 200:
                        return response;
                    case 401:
                        throw response.status;
                    default:
                        throw response.status;
                }
            } else {
                throw 0;
            }
        } catch (err) {
            throw new Error(err);
        }
    }

    static async uploadImageData(formData) {
        try {
            const response = await axios.post('/api/imageupload', formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
                validateStatus: (status) => status < 500,
            });
            if (response) {
                switch (response.status) {
                    case 200:
                        return response;
                    case 401:
                        throw response.status;
                    default:
                        throw response.data;
                }
            } else {
                throw 0;
            }
        } catch (err) {
            throw new Error(err);
        }
    }

    static async uploadBible(formData) {
        const response = await axios.post('/api/bibleupdate', formData, {
            headers: {
                'content-type': 'multipart/form-data',
            },
            validateStatus: (status) => status < 500,
        });
        if (response) {
            switch (response.status) {
                case 200:
                    return response;
                case 400:
                    throw Error(response.data.status);
                case 401:
                    throw Error(response.status);
                default:
                    throw Error(response.data);
            }
        } else {
            throw Error(0);
        }
    }

    static async pollCompleted() {
        const response = await axios.get('/api/bibleupdatecompletion');
        if (response) {
            return response;
        }
    }

    static async replaceShopifyImg(payload) {
        const response = await axios.post(
            '/api/replaceshopifyimg',
            { payload },
            {
                validateStatus: (status) => status < 500,
            },
        );
        if (response) {
            switch (response.status) {
                case 200:
                    return response;
                case 400:
                    throw Error(response.data.status);
                case 401:
                    throw Error(response.status);
                default:
                    throw Error(response.data);
            }
        } else {
            throw Error(0);
        }
    }

    static async newShopifyImg(payload) {
        const response = await axios.post(
            '/api/newshopifyimg',
            { payload },
            {
                validateStatus: (status) => status < 500,
            },
        );
        if (response) {
            switch (response.status) {
                case 200:
                    return response;
                case 400:
                    throw Error(response.data.status);
                case 401:
                    throw Error(response.status);
                default:
                    throw Error(response.data);
            }
        } else {
            throw Error(0);
        }
    }

    static async getCollected(list) {
        try {
            const response = await axios.post(
                `/api/getcollected`,
                { list },
                {
                    validateStatus: (status) => status < 500,
                },
            );
            if (response) {
                switch (response.status) {
                    case 200:
                        return response;
                    case 401:
                        throw response.status;
                    default:
                        throw response.status;
                }
            } else {
                throw 0;
            }
        } catch (err) {
            throw new Error(err);
        }
    }

    static async deleteCollected(list) {
        try {
            const response = await axios.post(
                `/api/deletecollected`,
                { list, removeset: true },
                {
                    validateStatus: (status) => status < 500,
                },
            );
            if (response) {
                switch (response.status) {
                    case 200:
                        return response;
                    case 401:
                        throw response.status;
                    default:
                        throw response.status;
                }
            } else {
                throw 0;
            }
        } catch (err) {
            throw new Error(err);
        }
    }

    static async getOrphans() {
        try {
            const response = await axios.post(
                `/api/getorphans`,
                {},
                {
                    validateStatus: (status) => status < 500,
                },
            );
            if (response) {
                switch (response.status) {
                    case 200:
                        return response;
                    case 401:
                        throw response.status;
                    default:
                        throw response.status;
                }
            } else {
                throw 0;
            }
        } catch (err) {
            throw new Error(err);
        }
    }

    static async getInvisible() {
        console.log('here');
        try {
            const response = await axios.post(
                `/api/getinvisible`,
                {},
                {
                    validateStatus: (status) => status < 500,
                },
            );
            if (response) {
                switch (response.status) {
                    case 200:
                        return response;
                    case 401:
                        throw response.status;
                    default:
                        throw response.status;
                }
            } else {
                throw 0;
            }
        } catch (err) {
            throw new Error(err);
        }
    }
}
