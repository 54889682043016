import { createApp } from 'vue';
import layout from './layouts/mainlayout';
import router from './router';
import store from './store';

const app = createApp(layout);

//define the variant of library being viewed to display relevant branding and for passing to API
let libraryVariant = 'bsl';
const hostname = window.location.hostname;
if (hostname.indexOf('wilkinsonsport') != -1) {
    libraryVariant = 'wsl';
}
if (hostname.indexOf('nielslarsen') != -1) {
    libraryVariant = 'niels';
}

app.provide('libraryVariant', libraryVariant);

import './sass/app.scss';

app.use(store)
    .use(router)
    .mount('#app');
