<template>
    <div class="item">
        <div class="image">
            <img :src="'./staticimages/imagethumbs/' + itemData.jpg_filename" :title="imgTitleContent" />
        </div>

        <div class="pid">{{ displayPid }}</div>
        <div class="itemDetails">
            <div class="content">{{ itemData.generated_description_combined }}</div>
            <div class="pageDetails">
                <div class="pageDetailsTitle">File:</div>
                <div class="pageDetailsData">{{ displayFileName }}</div>
            </div>
            <div class="pageDetails" v-if="libraryVariant == 'bsl'">
                <div class="pageDetailsTitle">Page No:</div>
                <div class="pageDetailsData">{{ itemData.page_no }}</div>
            </div>
            <div class="pageDetails" v-if="libraryVariant == 'bsl'">
                <div class="pageDetailsTitle">Category:</div>
                <div class="pageDetailsData pageDetailsData__category">
                    {{ sizeCategory(itemData.bsl_category1) }}
                </div>
            </div>
            <div class="buttonsContainer">
                <div class="linkButtons">
                    <div class="btnGroup">
                        <button @click="getFile(true, itemData.base_filename, 'jpg', 'jpg', downloadBaseName)">
                            JPG Standard
                        </button>
                        <button :class="{ disabled: itemData.has_jpeg_high == 0 }" @click="getFile(itemData.has_jpeg_high, itemData.base_filename, 'jpg', 'jpg_high', downloadBaseName)">
                            JPG High Res
                        </button>
                    </div>
                </div>
                <div class="linkButtons">
                    <div class="btnGroup">
                        <button :class="{ disabled: itemData.has_tiff == 0 }" @click="getFile(itemData.has_tiff, itemData.base_filename, 'tif', 'tif', downloadBaseName)">
                            TIFF
                        </button>
                        <button :class="{ disabled: itemData.has_pdf == 0 }" @click="getFile(itemData.has_pdf, itemData.base_filename, 'pdf', 'pdf', downloadBaseName)">
                            PDF
                        </button>
                    </div>
                </div>
                <div v-if="isAdmin" class="adminSection">
                    <div class="visibleIn">{{ visibleIn }}</div>
                    <div>
                        <button @click="edit">Edit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, inject, ref } from 'vue';
import { useRouter } from 'vue-router';
export default {
    props: ['itemData', 'isAdmin', 'thisTerms', 'thisScope'],
    setup(props) {
        const router = useRouter();
        const libraryVariant = inject('libraryVariant', 'bsl');
        const visibleIn = ref('Not Showing in Libraries');

        if (props.itemData.show_bsl) {
            visibleIn.value = 'BSL';
            if (props.itemData.show_wsl) {
                visibleIn.value += ' & WSL';
            }
        } else if (props.itemData.show_wsl) {
            visibleIn.value = 'WSL';
        }

        const displayPid = computed(() => {
            if (libraryVariant == 'niels') {
                return props.itemData.niels_mapped_code;
            } else {
                return props.itemData.mapped_code;
            }
        });

        const displayFileName = computed(() => {
            if (libraryVariant == 'niels') {
                return 'generated';
            } else {
                return props.itemData.jpg_filename;
            }
        });

        const downloadBaseName = computed(() => {
            if (libraryVariant == 'niels') {
                return props.itemData.niels_mapped_code.replace(/[^a-z0-9._-]/gi, '');
            } else {
                return props.itemData.base_filename;
            }
        });

        const imgTitleContent = computed(() => {
            if (typeof props.itemData.description3 != 'string' || props.itemData.description3.length < 1) {
                return '';
            }
            return props.itemData.description3.trim();
        });

        const sizeCategory = ref((cat) => {
            if (typeof cat != 'string' || cat.length < 1) {
                return '';
            }
            //break off the main category
            let mainCat = cat.split('|')[0];
            //max 36 chars
            if (mainCat.length > 36) {
                mainCat = mainCat.substr(0, 33) + '...';
            }
            return mainCat;
        });

        const getFile = ref((hasType, fileBase, ext, type, downloadBaseName) => {
            if (hasType) {
                const fname = fileBase + '.' + ext;
                const downloadname = downloadBaseName + '.' + ext;
                if (window.location.port > 1000) {
                    //for dev servers
                    window.location.replace(`http://127.0.0.1:5000/api/download?fname=${fname}&type=${type}&downloadname=${downloadname}`);
                } else {
                    window.location.replace(`/api/download?fname=${fname}&type=${type}&downloadname=${downloadname}`);
                }
            }
        });

        const edit = ref(() => {
            //now using state to pass which basename was clicked to adminedit page
            router.push({
                name: 'AdminEdit',
                state: {
                    edit_basename: props.itemData.base_filename,
                },
            });
        });

        return { displayPid, displayFileName, downloadBaseName, libraryVariant, visibleIn, sizeCategory, getFile, edit, imgTitleContent };
    },
};
</script>

<style lang="scss" scoped>
@import '../sass/_variables.scss';

.item {
    width: 270px;
    margin: 0 auto 15px auto;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 16px;

    .image {
        height: 268px;
        background-size: unset;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url('../assets/search-item-placeholder.gif');
        text-align: center;

        img {
            display: inline-block;
            width: 268px;
            height: 268px;
        }
    }

    .pid {
        background-color: #b1cfeb;
        text-align: center;
        font-size: 1.2em;
        font-weight: 600;
    }

    .itemDetails {
        background-color: #f3f3f3;
        margin-bottom: 1px;

        .content {
            padding: 10px;
            text-align: justify;
            font-size: 0.9em;
            height: 60px;
            overflow: hidden;
        }

        .pageDetails {
            margin: 10px;
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            background-color: #c9ddef;
            border: 1px solid #b1cfeb;
            border-radius: 5px;

            .pageDetailsTitle {
                background-color: #b1cfeb;
                flex: 0 0 75px;
                padding: 2px 0 2px 3px;
                font-size: 0.9em;
                font-weight: 600;
                line-height: 25px;
                border-radius: 5px 0 0 5px;
            }

            .pageDetailsData {
                text-align: right;
                flex: 0 1 auto;
                padding: 2px 3px 2px 0;
                font-size: 0.9em;
                line-height: 25px;

                &__category {
                    height: 4em;
                    word-break: break-all;
                }
            }
        }

        .buttonsContainer {
            margin: 20px 10px 0 10px;
            padding-bottom: 10px;

            .linkButtons {
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: stretch;
                padding-bottom: 10px;

                .btnGroup {
                    width: 100%;
                    position: relative;
                    display: inline-flex;
                    vertical-align: middle;

                    button {
                        position: relative;
                        flex: 1 1 auto;
                        margin: 0;
                        overflow: hidden;
                        text-transform: none;
                        display: inline-block;
                        font-size: 16px;
                        font-weight: 400;
                        text-align: center;
                        white-space: nowrap;
                        vertical-align: middle;
                        user-select: none;
                        border: 1px solid transparent;
                        padding: 6px 12px;
                        line-height: 1.5;
                        border-radius: 4px;
                        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                        color: #fff;
                        background-color: #335cb7;

                        &:hover {
                            background-color: #0069d9;
                        }

                        &:first-child {
                            margin-left: 0;
                        }

                        &:not(:first-child) {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                            margin-left: -1px;
                            border-left-color: #9bc0ff;

                            &.disabled {
                                border-left-color: #d8d8d8;
                            }
                        }

                        &:not(:last-child) {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }

                        &:not(.disabled) {
                            cursor: pointer;
                        }

                        &.disabled {
                            color: #9ba3ab;
                            background-color: transparent;
                            border: 1px solid #d8d8d8;

                            &:hover {
                                background-color: transparent;
                            }
                        }
                    }
                }
            }

            .adminSection {
                .visibleIn {
                    text-align: center;
                    background-color: #ccc;
                    font-size: 0.8em;
                    margin-bottom: 0.5em;
                }

                button {
                    width: 100%;
                    user-select: none;
                    border: 1px solid transparent;
                    background-color: $orange;
                    padding: 5px;
                    border-radius: 5px;
                    color: #fff;
                    font-weight: 600;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
