<template>
    <MsgOverlay></MsgOverlay>
    <AdminBar v-if="isAdmin"></AdminBar>
    <div class="container" :class="{ outercontainer: isAdmin == true }">
        <div class="variantContainer" v-if="libraryVariant == 'wsl'">
            <div class="topblock block">
                <img src="../assets/wilkinson-logo.jpg" />
                <div class="topblock__contact">
                    <ul>
                        <li>01753 647200</li>
                        <li><a href="mailto:sales@wilkinsonsport.co.uk">sales@wilkinsonsport.co.uk</a></li>
                        <li>Wilkinson Sports & Leisure,<br />Bishop House, East Burnham Park, Crown Lane,<br />Farnham Royal, Slough, Berkshire, SL2 3SF.</li>
                    </ul>
                </div>
            </div>
            <div style="clear:both;"></div>
            <header class="title is-3 block has-background-primary has-text-light">
                <div>WSL Image Library</div>
                <div @click="toggleLowDetail">
                    <svg v-if="!lowDetailMode" enable-background="new 0 0 384 512" version="1.1" viewBox="0 0 384 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                        <g focusable="false">
                            <path d="m288 248v28c0 6.6-5.4 12-12 12h-168c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72h-168c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1v332.1c0 26.5-21.5 48-48 48h-288c-26.5 0-48-21.5-48-48v-416c0-26.5 21.5-48 48-48h204.1c12.7 0 24.9 5.1 33.9 14.1l83.9 83.9c9 8.9 14.1 21.2 14.1 33.9zm-128-80v76.1h76.1l-76.1-76.1zm80 412.1v-288h-104c-13.3 0-24-10.7-24-24v-104h-160v416h288z" />
                        </g>
                    </svg>
                    <svg v-else enable-background="new 0 0 384 512" version="1.1" viewBox="0 0 384 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                        <g focusable="false">
                            <path d="m288 248v28c0 6.6-5.4 12-12 12h-168c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72h-168c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1v332.1c0 26.5-21.5 48-48 48h-288c-26.5 0-48-21.5-48-48v-416c0-26.5 21.5-48 48-48h204.1c12.7 0 24.9 5.1 33.9 14.1l83.9 83.9c9 8.9 14.1 21.2 14.1 33.9zm-128-80v76.1h76.1l-76.1-76.1zm80 412.1v-288h-104c-13.3 0-24-10.7-24-24v-104h-160v416h288z" />
                        </g>
                        <polygon style="fill:red" points="384 0 384 40.2 34.9 512 0 512 0 471.8 349.1 0" />
                    </svg>
                    <router-link to="/login">
                        <i class="fas fa-sign-out-alt"></i>
                    </router-link>
                </div>
            </header>
        </div>
        <div class="variantContainer" v-else-if="libraryVariant == 'niels'">
            <div class="topblock block">
                <img src="../assets/NLL-LOGO.jpg" />
                <div class="topblock__contact">
                    <ul>
                        <li>01924 283000</li>
                        <li><a href="mailto:sales@wilkinsonsport.co.uk">sales@nielslarsen.co.uk</a></li>
                        <li>Niels Larsen Ltd<br />Bishop House, East Burnham Park, Crown Lane,<br />Farnham Royal, Slough, Berkshire, SL2 3SF.</li>
                    </ul>
                </div>
            </div>
            <div style="clear:both;"></div>
            <header class="title is-3 block has-background-primary has-text-light">
                <div>Niels Larsen Image Library</div>
                <div @click="toggleLowDetail">
                    <svg v-if="!lowDetailMode" enable-background="new 0 0 384 512" version="1.1" viewBox="0 0 384 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                        <g focusable="false">
                            <path d="m288 248v28c0 6.6-5.4 12-12 12h-168c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72h-168c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1v332.1c0 26.5-21.5 48-48 48h-288c-26.5 0-48-21.5-48-48v-416c0-26.5 21.5-48 48-48h204.1c12.7 0 24.9 5.1 33.9 14.1l83.9 83.9c9 8.9 14.1 21.2 14.1 33.9zm-128-80v76.1h76.1l-76.1-76.1zm80 412.1v-288h-104c-13.3 0-24-10.7-24-24v-104h-160v416h288z" />
                        </g>
                    </svg>
                    <svg v-else enable-background="new 0 0 384 512" version="1.1" viewBox="0 0 384 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                        <g focusable="false">
                            <path d="m288 248v28c0 6.6-5.4 12-12 12h-168c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72h-168c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1v332.1c0 26.5-21.5 48-48 48h-288c-26.5 0-48-21.5-48-48v-416c0-26.5 21.5-48 48-48h204.1c12.7 0 24.9 5.1 33.9 14.1l83.9 83.9c9 8.9 14.1 21.2 14.1 33.9zm-128-80v76.1h76.1l-76.1-76.1zm80 412.1v-288h-104c-13.3 0-24-10.7-24-24v-104h-160v416h288z" />
                        </g>
                        <polygon style="fill:red" points="384 0 384 40.2 34.9 512 0 512 0 471.8 349.1 0" />
                    </svg>
                    <router-link to="/login">
                        <i class="fas fa-sign-out-alt"></i>
                    </router-link>
                </div>
            </header>
        </div>
        <div class="variantContainer" v-else>
            <div class="topblock block">
                <img src="../assets/BISHOP-SPORT-300.jpg" />
                <div class="topblock__contact">
                    <ul>
                        <li>01753 647200</li>
                        <li><a href="mailto:sales@bishopsport.co.uk">sales@bishopsport.co.uk</a></li>
                        <li>Bishop Sports & Leisure Limited,<br />Bishop House, East Burnham Park, Crown Lane,<br />Farnham Royal, Slough, Berkshire, SL2 3SF.</li>
                    </ul>
                </div>
            </div>
            <div style="clear:both;"></div>
            <header class="title is-3 block has-background-primary has-text-light">
                <div>BSL Image Library</div>
                <div>
                    <svg v-if="isAdmin" @click="toggleLowDetail" enable-background="new 0 0 384 512" version="1.1" viewBox="0 0 384 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                        <g focusable="false">
                            <path d="m288 248v28c0 6.6-5.4 12-12 12h-168c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72h-168c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1v332.1c0 26.5-21.5 48-48 48h-288c-26.5 0-48-21.5-48-48v-416c0-26.5 21.5-48 48-48h204.1c12.7 0 24.9 5.1 33.9 14.1l83.9 83.9c9 8.9 14.1 21.2 14.1 33.9zm-128-80v76.1h76.1l-76.1-76.1zm80 412.1v-288h-104c-13.3 0-24-10.7-24-24v-104h-160v416h288z" />
                        </g>
                        <polygon v-if="lowDetailMode" style="fill:red" points="384 0 384 40.2 34.9 512 0 512 0 471.8 349.1 0" />
                    </svg>
                    <router-link to="/login">
                        <i class="fas fa-sign-out-alt"></i>
                    </router-link>
                </div>
            </header>
        </div>
        <router-view v-slot="{ Component }">
            <transition name="pagetrans" mode="out-in">
                <component :is="Component"></component>
            </transition>
        </router-view>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, inject, ref } from 'vue';
import MsgOverlay from '../components/msgOverlay';
import AdminBar from '../components/AdminBar';
export default {
    name: 'MainLayout',
    components: {
        MsgOverlay,
        AdminBar,
    },
    setup() {
        const libraryVariant = inject('libraryVariant', 'bsl');
        const store = useStore();
        const isAdmin = computed(() => store.getters.getIsAdmin);
        const lowDetailMode = computed(() => store.getters.getLowDetailMode);

        const toggleLowDetail = ref(() => {
            store.commit('setLowDetailMode', !lowDetailMode.value);
        });

        return { libraryVariant, isAdmin, lowDetailMode, toggleLowDetail };
    },
};
</script>
<style lang="scss" scoped>
.outercontainer {
    margin-top: 50px;
}

.variantContainer {
    margin-bottom: 1.5em;
}

svg {
    fill: white;
    height: 22px;
    margin-right: 20px;
    cursor: pointer;

    &:hover {
        fill: #b8cefd;
    }
}

.pagetrans-enter-from {
    opacity: 0;
    transform: translateY(-50px);
}

.pagetrans-enter-active {
    transition: all 0.5s ease;
}

.pagetrans-leave-to {
    opacity: 0;
    transform: translateY(-50px);
}

.pagetrans-leave-active {
    transition: all 0.5s ease;
}
</style>
