<template>
    <div>
        <div class="title is-4 has-text-grey">Add Image To Library</div>
        <div class="productsearch">
            <PidSelector @pidSelected="pidSelected($event)" @apiError="handleApiError($event)" @clearData="clearData" :startPid="startPid"></PidSelector>
        </div>
        <transition name="fade" mode="out-in">
            <div v-if="itemDataExisting.length">
                <hr />
                <div class="title is-6 has-text-grey">Existing Images</div>
                <ExistProdTbl :itemDataExisting="itemDataExisting"></ExistProdTbl>
            </div>
        </transition>
        <transition name="fade" mode="out-in">
            <div class="createFirstNote title is-5 has-text-grey" v-if="createFirst">
                <hr />
                <p>That Product Currently has No Images - You can add its first!</p>
            </div>
        </transition>
        <transition name="fade" mode="out-in">
            <div v-if="itemDataExisting.length || createFirst">
                <hr />
                <div class="uploadContainer">
                    <div class="title is-6 has-text-grey mb-4">Upload New</div>
                </div>
                <div class="message is-danger">
                    <div class="message-body title is-5 has-text-centered mb-6">
                        The Base Name for Each File Type Must be
                        {{ itemDataNew.base_filename }}
                    </div>
                </div>
                <fileUpload :isEdit="false" :itemData="itemDataNew" @actionCompleted="startAnotherFromSame()"></fileUpload>
                <hr />
                <div class="title is-6 has-text-grey">Bible Data</div>
                <bibleDataTable :pid="itemDataNew.mapped_code"></bibleDataTable>
            </div>
        </transition>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import PidSelector from '../components/PidSelector';
import ExistProdTbl from '../components/ExistProdTbl';
import FileUpload from '../components/FileUpload';
import bibleDataTable from '../components/BibleDataTable';
import Api from '../apirequests';
export default {
    name: 'AdminNew',
    components: { PidSelector, ExistProdTbl, FileUpload, bibleDataTable },
    setup() {
        //needs to know when a PID has been selected
        //then push that off to existing image table comp
        const store = useStore();
        const router = useRouter();
        const itemDataExisting = ref([]);
        const createFirst = ref(false);
        const startPid = ref('');
        const itemDataNew = ref({
            has_jpg_high: 0,
            has_tiff: 0,
            has_pdf: 0,
            show_bsl: 1,
            show_wsl: 0,
        });

        onMounted(() => {
            if (history.state.new_mapped_code) {
                startPid.value = history.state.new_mapped_code;
                pidSelected.value(history.state.new_mapped_code);
            }
        });

        const pidSelected = ref((pid) => {
            //emitted from pidselector comp when pid clicked
            if (pid == 'Product Not Found') {
                store.commit('setMsg', {
                    header: 'Product Not Found',
                    body: 'Update the NAV Data To Include This Product',
                });
                return;
            }
            if (typeof pid != 'string' || pid.length < 1) {
                store.commit('setMsg', {
                    header: 'Notice',
                    body: `An impossible Product Was Reqested`,
                });
                return;
            }
            //all good
            history.state.new_mapped_code = pid;
            itemDataNew.value.mapped_code = pid;
            itemDataNew.value.base_filename = pid.replace(/[^a-z0-9-_.]/i, '_');
            //load data for pid
            loadExistingImgData(pid);
        });

        const loadExistingImgData = (pid) => {
            const loadArgs = {
                terms: pid,
                scope: 'b.item_no',
                libraryVariant: 'all',
                exact: true
            };
            Api.libSearch(loadArgs)
                .then((response) => {
                    if (Array.isArray(response.data) && response.data.length) {
                        calcNewIndex(response.data);
                        itemDataExisting.value = response.data;
                    } else {
                        //selected product has no existing images - new basename is already correct at the default - move to Add First mode
                        createFirst.value = true;
                    }
                })
                .catch((err) => {
                    handleApiError.value(err.message);
                });
        };

        const calcNewIndex = (responseData) => {
            //there is at least one existing image
            //create an array of any existing indexed images
            let existingIndexes = [];
            responseData.forEach((img) => {
                const matches = img.base_filename.match(/\[(\d+)\]$/);
                //if it has an index the value is returned as the second element in the matches array
                if (Array.isArray(matches) && matches.length > 1 && !isNaN(matches[1])) {
                    existingIndexes.push(parseInt(matches[1]));
                }
            });
            let findIndex = 1;
            let newIndex = false;
            while (findIndex < 500 && !newIndex) {
                newIndex = -1 === existingIndexes.indexOf(findIndex) ? findIndex : false;
                findIndex++;
            }
            itemDataNew.value.base_filename = itemDataNew.value.base_filename + `[${newIndex}]`;
        };

        const clearData = ref(() => {
            itemDataExisting.value = [];
            itemDataNew.value.has_jpg_high = 0;
            itemDataNew.value.has_tiff = 0;
            itemDataNew.value.has_pdf = 0;
            itemDataNew.value.show_bsl = 1;
            itemDataNew.value.show_wsl = 0;
            createFirst.value = false;
        });

        const handleApiError = ref((statusOrError) => {
            switch (statusOrError) {
                case '401' || 401:
                    store.commit('setMsg', {
                        header: 'Notice',
                        body: 'Not Logged In',
                    });
                    router.push({ name: 'Login' });
                    break;
                default:
                    store.commit('setMsg', {
                        header: 'Notice',
                        body: `An error ${statusOrError} occured`,
                    });
            }
        });

        const startAnotherFromSame = ref(() => {
            pidSelected.value(itemDataNew.value.mapped_code);
        });

        return {
            startPid,
            pidSelected,
            handleApiError,
            itemDataExisting,
            clearData,
            createFirst,
            itemDataNew,
            startAnotherFromSame,
        };
    },
};
</script>

<style lang="scss" scoped>
.productsearch {
    margin: 0 auto;
    width: 18em;
}

.createFirstNote {
    text-align: center;
}

.newbutton {
    text-align: center;
}
</style>
