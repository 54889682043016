<template>
    <div v-if="dataLoaded">
        <div class="title is-4 has-text-grey">Edit Image Set {{ itemData.base_filename }}</div>
        <div class="thumbnailContainer">
            <div class="imageThumb">
                <div class="thumbCaption">Image Library</div>
                <img :src="'/staticimages/imagethumbs/' + itemData.jpg_filename" />
            </div>
            <div class="imageThumb">
                <div class="thumbCaption">Shopify Current</div>
                <img v-if="itemData.shopifyData.imageData" :src="itemData.shopifyData.imageData.shopify_image_url" />
            </div>
        </div>
        <fileUpload :isEdit="true" :itemData="itemData" @actionCompleted="refreshData"></fileUpload>
        <div class="addNewButtonContainer">
            <button class="button is-info is-medium" @click="addNew">Add New</button>
        </div>
        <div class="title is-6 has-text-grey mt-6">Bible Data</div>
        <bibleDataTable :pid="itemData.mapped_code"></bibleDataTable>
        <div class="title is-6 has-text-grey mt-6">Shopify Active Image</div>
        <shopifySync :itemData="itemData"></shopifySync>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import fileUpload from '../components/FileUpload';
import bibleDataTable from '../components/BibleDataTable';
import shopifySync from '../components/ShopifySync.vue';
import Api from '../apirequests';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
export default {
    name: 'AdminEdit',
    components: { fileUpload, bibleDataTable, shopifySync },
    setup() {
        const store = useStore();
        const router = useRouter();
        const dataLoaded = ref(false);
        const itemData = ref({});

        //criteria data is now in browser history state object - as passing props through router no longer practical
        const basename = ref(history.state.edit_basename);

        if (typeof basename.value != 'string') {
            alert('No basename passed');
        }

        const doApi = () => {
            Api.dataForImage(basename.value || 'AE1020')
                .then((response) => {
                    if (response.data) {
                        //will be a single record object or false
                        itemData.value = response.data;
                        dataLoaded.value = true;
                    } else {
                        handleApiError.value('Image Not Found');
                    }
                })
                .catch((err) => {
                    handleApiError.value(err.message);
                });
        };

        const handleApiError = ref((statusOrError) => {
            switch (statusOrError) {
                case '401' || 401:
                    store.commit('setMsg', {
                        header: 'Notice',
                        body: 'Not Logged In',
                    });
                    router.push({ name: 'Login' });
                    break;
                default:
                    store.commit('setMsg', {
                        header: 'Notice',
                        body: `An error ${statusOrError} occured`,
                    });
            }
        });

        const addNew = () => {
            router.push({
                name: 'AdminNew',
                state: {
                    new_mapped_code: itemData.value.mapped_code,
                },
            });
        };

        const refreshData = () => {
            dataLoaded.value = false;
            doApi();
        };

        doApi();

        return { dataLoaded, itemData, addNew, refreshData };
    },
};
</script>

<style lang="scss" scoped>
.thumbnailContainer {
    min-width: 300px;
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    row-gap: 5px;
    margin-bottom: 2em;

    .thumbCaption {
        text-align: center;
    }

    .imageThumb {
        flex: 0 1 250px;
        background-color: #eee;

        img {
            max-height: 250px;
            padding: 2px;
        }
    }
}

.addNewButtonContainer {
    margin-top: 3em;
    text-align: center;
}
</style>
