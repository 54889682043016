<template>
    <div class="shopifyContainer">
        <p>Below is the image currently assigned to this product in Shopify</p>
        <div v-if="!templateData.foundShopifyVariant" class="message is-warning notFound">
            <div class="message-body">Product is not in Shopify</div>
        </div>
        <div v-else-if="!templateData.foundShopifyImage" class="message is-warning notFound">
            <div class="message-body">No Image Assigned</div>
        </div>
        <div v-else>
            <div class="shopifyImg"><img :src="templateData.shopifyImgUrl" alt=""></div>
            <div class="shopifyDataTable">
                <table class="table adminTable">
                    <thead>
                        <tr>
                            <th>Field</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Shopify Product ID:</td>
                            <td>{{ templateData.shopifyProductGid }}</td>
                        </tr>
                        <tr>
                            <td>Shopify Variant ID:</td>
                            <td>{{ templateData.shopifyVariantGid }}</td>
                        </tr>
                        <tr>
                            <td>Shopify Image ID:</td>
                            <td>{{ templateData.shopifyImageGid }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-if="templateData.foundShopifyVariant">
            <div class="altTextContainer">
                <label class="label">Alt Text:</label>
                <input type="text" name="textinput" class="input" v-model="templateData.shopifyAltText" />
            </div>
            <button class="button is-warning is-medium" :disabled="buttonDisabled" @click="modifyShopify" :title="buttonCaption">{{ buttonCaption }}</button>
        </div>
    </div>
</template>

<script>
import { computed, ref, toRefs, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Api from '../apirequests';
import hae from '../includes/HandleApiError';
export default {
    name: 'shopifySync',
    props: ['itemData'],
    setup(props) {
        const store = useStore();
        const router = useRouter();
        const templateData = ref({
            'foundShopifyVariant': false,
            'foundShopifyImage': false,
            'shopifyImgUrl': null,
            'shopifyAltText': '',
            'shopifyProductGid': null,
            'shopifyVariantGid': null,
            'shopifyImageGid': null,
        });
        const buttonDisabled = ref(true);
        const buttonCaption = ref(null);
        const buttonAction = ref(null);

        const { jpg_filename, mapped_code, has_jpeg_high, generated_description_combined, shopifyData } = toRefs(props.itemData);

        const initImageData = (shopify_image_url, alt_text, shopify_product_gid, shopify_variant_gid, shopify_image_gid) => {
            templateData.value.foundShopifyImage = true;
            templateData.value.shopifyImgUrl = shopify_image_url;
            templateData.value.shopifyAltText = alt_text;
            templateData.value.shopifyProductGid = shopify_product_gid;
            templateData.value.shopifyVariantGid = shopify_variant_gid;
            templateData.value.shopifyImageGid = shopify_image_gid;
        };

        if (shopifyData && shopifyData.value.variantData) {
            templateData.value.foundShopifyVariant = true;
            templateData.value.shopifyAltText = generated_description_combined.value;
            if (shopifyData.value.imageData) {
                initImageData(shopifyData.value.imageData.shopify_image_url, shopifyData.value.imageData.alt_text, shopifyData.value.imageData.shopify_product_gid, shopifyData.value.imageData.shopify_variant_gid, shopifyData.value.imageData.shopify_image_gid);
            }
        }

        const initButton = () => {
            if (!has_jpeg_high.value) {
                buttonCaption.value = 'Needs High-Res JPG in Library';
            } else {
                buttonDisabled.value = false;
                if (templateData.value.foundShopifyVariant && !templateData.value.foundShopifyImage) {
                    buttonCaption.value = 'Use Current Image on Shopify';
                    buttonAction.value = 'new';
                }
                if (templateData.value.foundShopifyVariant && templateData.value.foundShopifyImage) {
                    buttonCaption.value = 'Replace Shopify Image with Current';
                    buttonAction.value = 'replace';
                }
            }
        };

        initButton();

        //watch the msgClick in store for changes - user reacted to message box
        const msgClickVal = computed(() => store.getters.getMsgClickVal);
        watch(msgClickVal, () => {
            if (store.getters.getMsgOwner == 'shopifySync' && msgClickVal.value != null) {
                //might as well reset the owner here - will get set each time anyway
                store.commit('setMsgOwner', null);
                hanldeMsgClick(msgClickVal.value);
            }
        });

        const hanldeMsgClick = (msgClickVal) => {
            switch (msgClickVal) {
                case 'newShopifyOk':
                    //ok to replace image on Shopify
                    store.commit('setMsg', null);
                    actionNewShopifyImage();
                    break;
                case 'replaceShopifyOk':
                    //ok to replace image on Shopify
                    store.commit('setMsg', null);
                    actionReplaceShopify();
                    break;
                default:
                    store.commit('setMsg', null);
            }
            store.commit('setMsgButtons', null);
            store.commit('setMsgClickVal', null);
        };

        const modifyShopify = ref(() => {
            if (typeof templateData.value.shopifyAltText != 'string' || templateData.value.shopifyAltText.length < 1) {
                store.commit('setMsgOwner', 'shopifySync');
                store.commit('setMsgButtons', [
                    { caption: 'Ok', val: 'altTextIssueOk' }
                ]);
                store.commit('setMsg', {
                    header: 'Missing',
                    body: `The Alt Text must be populated`,
                });
                return;
            }
            if (buttonAction.value == 'replace' && (!templateData.value.shopifyProductGid || !templateData.value.shopifyVariantGid)) {
                store.commit('setMsgOwner', 'shopifySync');
                store.commit('setMsgButtons', [
                    { caption: 'Ok', val: 'missingShopifyDataOk' }
                ]);
                store.commit('setMsg', {
                    header: 'Missing',
                    body: `Shopify Data is Missing. Cannot continue`,
                });
                return;
            }

            switch (buttonAction.value) {
                case 'new':
                    uploadNewShopifyImage();
                    break;
                case 'replace':
                    replaceShopifyImage();
                    break;
            }
        });

        const uploadNewShopifyImage = () => {
            store.commit('setMsgOwner', 'shopifySync');
            store.commit('setMsgButtons', [
                { caption: 'Cancel', val: 'newShopifyCancel' },
                { caption: 'Upload', val: 'newShopifyOk' },
            ]);
            store.commit('setMsg', {
                header: 'Confirm',
                body: `Confirm current image ${jpg_filename.value} to be used on Shopify for SKU ${mapped_code.value}`,
            });
        };

        const replaceShopifyImage = () => {
            store.commit('setMsgOwner', 'shopifySync');
            store.commit('setMsgButtons', [
                { caption: 'Cancel', val: 'replaceShopifyCancel' },
                { caption: 'Replace', val: 'replaceShopifyOk' },
            ]);
            store.commit('setMsg', {
                header: 'Confirm',
                body: `Confirm image ${jpg_filename.value} to replace current for SKU ${shopifyData.value.imageData.bsl_code} on Shopify`,
            });
        };

        const actionNewShopifyImage = async () => {
            store.commit('setMsg', 'WORKING');
            const payload = {
                'shopify_variant_id': shopifyData.value.variantData.shopify_id,
                'library_filename': jpg_filename.value,
                'alt_text': templateData.value.shopifyAltText
            };
            try {
                const response = await Api.newShopifyImg(JSON.stringify(payload));
                if (response) {
                    //reponse.data is already JSON parsed to object
                    const { newImageId, newImageUrl, newImageAltText, newImageProductId, newImageVariantId } = response.data;
                    initImageData(newImageUrl, newImageAltText, newImageProductId, newImageVariantId, newImageId);
                    initButton();
                    store.commit('setMsg', {
                        header: 'Done',
                        body: `Imported Shopify Image`,
                    });
                }
            } catch (err) {
                hae(store, router, err.message);
            }
        };

        const actionReplaceShopify = async () => {
            store.commit('setMsg', 'WORKING');
            const payload = {
                'shopify_product_id': templateData.value.shopifyProductGid,
                'shopify_variant_id': templateData.value.shopifyVariantGid,
                'shopify_image_id': templateData.value.shopifyImageGid,
                'library_filename': jpg_filename.value,
                'alt_text': templateData.value.shopifyAltText
            };
            try {
                const response = await Api.replaceShopifyImg(JSON.stringify(payload));
                if (response) {
                    //reponse.data is already JSON parsed to object
                    const { newImageId, newImageUrl } = response.data;
                    templateData.value.shopifyImageGid = newImageId;
                    templateData.value.shopifyImgUrl = newImageUrl;
                    store.commit('setMsg', {
                        header: 'Done',
                        body: `Shopify Image Replaced`,
                    });
                }
            } catch (err) {
                hae(store, router, err.message);
            }
        };

        return { templateData, buttonDisabled, buttonCaption, modifyShopify };
    },
};
</script>

<style lang="scss" scoped>
.shopifyContainer {
    text-align: center;

    p {
        font-size: 1.1em;
        font-weight: 600;
    }

    .notFound {
        display: inline-block;
        font-size: 1.3em;
        font-weight: 600;
        margin-top: 3em;
    }

    .shopifyImg {
        margin-top: 3em;


        img {
            max-height: 500px;
        }
    }

    .altTextContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
        margin-top: 2em;

        label {
            display: inline;
            margin: 0;
            font-weight: 400;
        }

        input {
            max-width: 50em;
        }
    }

    .shopifyDataTable {
        table {
            margin: 2em auto 0 auto;
        }
    }

    button {
        margin: 3em auto;
    }
}
</style>
