import { createStore } from 'vuex';

export default createStore({
    devtools: true,
    state: {
        msg: null,
        msgOwner: null,
        msgButtons: null,
        msgClickVal: null,
        hasToken: false,
        isAdmin: false,
        lastTerms: false,
        lastLimitArea: 'all',
        loadDefined: false,
        lowDetailMode: false,
        listSelection: [],
        collectedItems: [],
    },
    getters: {
        getMsg(state) {
            return state.msg;
        },
        getMsgOwner(state) {
            return state.msgOwner;
        },
        getMsgButtons(state) {
            return state.msgButtons;
        },
        getMsgClickVal(state) {
            return state.msgClickVal;
        },
        getHasToken(state) {
            return state.hasToken;
        },
        getIsAdmin(state) {
            return state.isAdmin;
        },
        getLastTerms(state) {
            return state.lastTerms;
        },
        getLastLimitArea(state) {
            return state.lastLimitArea;
        },
        getLoadDefined(state) {
            return state.loadDefined;
        },
        getLowDetailMode(state) {
            return state.lowDetailMode;
        },
        getListSelection(state) {
            return state.listSelection;
        },
        getCollectedItems(state) {
            return state.collectedItems;
        },
    },
    mutations: {
        setMsg(state, val) {
            state.msg = val;
        },
        setMsgOwner(state, val) {
            state.msgOwner = val;
        },
        setMsgButtons(state, val) {
            state.msgButtons = val;
        },
        setMsgClickVal(state, val) {
            state.msgClickVal = val;
        },
        setHasToken(state, val) {
            state.hasToken = val;
        },
        setIsAdmin(state, val) {
            state.isAdmin = val;
        },
        setLastTerms(state, val) {
            state.lastTerms = val;
        },
        setLastLimitArea(state, val) {
            state.lastLimitArea = val;
        },
        setLoadDefined(state, val) {
            state.loadDefined = val;
        },
        setLowDetailMode(state, val) {
            state.lowDetailMode = val;
        },
        toggleSelectionInclusion(state, val) {
            const existingIndex = state.listSelection.indexOf(val);
            if (existingIndex == -1) {
                state.listSelection.push(val);
            } else {
                state.listSelection.splice(existingIndex, 1);
            }
        },
        clearSelection(state) {
            state.listSelection.splice(0);
        },
        addSelectedToCollected(state) {
            //need to modify the state array NOT replace it - else lose reactivity to some degree
            state.listSelection.forEach((item) => {
                if (state.collectedItems.indexOf(item) == -1) {
                    state.collectedItems.push(item);
                }
            });
        },
        clearCollected(state) {
            while (state.collectedItems.length) {
                state.collectedItems.pop();
            }
        },
    },
    actions: {}, //only required for async
    modules: {},
});
