<template>
    <table class="listViewTable">
        <tr>
            <th><input type="radio" name="toggleAll" :checked="allSelected" @click="toggleAll"></th>
            <th>Thumbnail</th>
            <th>JPG Filename</th>
            <th>BSL Product</th>
            <th>Niels Product</th>
            <th class="centre">BSL</th>
            <th class="centre">WSL</th>
            <th class="centre">JPG High</th>
            <th class="centre">TIFF</th>
            <th class="centre">PDF</th>
        </tr>
        <tr v-for="(item, index) in items" :key="index" @click="edit($event, item.base_filename)">
            <td><input type="radio" :name="item.base_filename" @click="toggleSelectedState($event)" :checked="currentSelectionState.indexOf(item.base_filename) != -1"></td>
            <td class="thumb"><img :src="'./staticimages/imagethumbs/' + item.jpg_filename" :title="trimString(item.description3)" /></td>
            <td>{{ item.jpg_filename }}</td>
            <td>{{ item.mapped_code }}</td>
            <td>{{ item.niels_mapped_code }}</td>
            <td class="centre"><i v-if="item.show_bsl" class="fas fa-check-circle has-text-success"></i><i v-else class="fas fa-times-circle dim"></i></td>
            <td class="centre"><i v-if="item.show_wsl" class="fas fa-check-circle has-text-success"></i><i v-else class="fas fa-times-circle dim"></i></td>
            <td class="centre"><i v-if="item.has_jpeg_high" class="fas fa-check-circle has-text-success"></i><i v-else class="fas fa-times-circle dim"></i></td>
            <td class="centre"><i v-if="item.has_tiff" class="fas fa-check-circle has-text-success"></i><i v-else class="fas fa-times-circle dim"></i></td>
            <td class="centre"><i v-if="item.has_pdf" class="fas fa-check-circle has-text-success"></i><i v-else class="fas fa-times-circle dim"></i></td>
        </tr>
    </table>
    <div class="listViewControls">
        <div class="controlGrid">
            <div class="actionLabel" style="grid-column: 1/2;">Action Selected</div>
            <div style="grid-column: 2/3; grid-row: 2/3;">
                <button @click="deleteSelected" :disabled="!currentSelectionState.length">Delete</button>
            </div>
            <div class="actionLabel" style="grid-column: 1/2; grid-row: 3/4;">Collected Items</div>
            <div style="grid-column: 2/3; grid-row: 4/5;">
                <button @click="addSelected" :disabled="!currentSelectionState.length">Add Selected</button>
            </div>
            <div style="grid-column: 3/4; grid-row: 4/5;">
                <button @click="clearCollected" :disabled="!currentCollectedState.length">Clear Collected</button>
            </div>
            <div style="grid-column: 4/5; grid-row: 4/5;">
                <button @click="loadCollected" :disabled="!currentCollectedState.length">Show Collected</button>
            </div>
            <div style="grid-column: 5/6; grid-row: 4/5;">
                <span>{{ currentCollectedState.length }} item(s) in collection</span>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default {
    props: ['items', 'isAdmin', 'thisTerms', 'thisScope'],
    emits: ['deleteSelected', 'loadCollected'],
    setup(props, context) {
        const router = useRouter();
        const store = useStore();
        const propItems = toRef(props, 'items');

        const allSelected = ref(false);
        const currentSelectionState = computed(() => store.getters.getListSelection);
        const currentCollectedState = computed(() => store.getters.getCollectedItems);

        const toggleSelectedState = ref((event) => {
            const itemBaseName = event.target.name;
            store.commit('toggleSelectionInclusion', itemBaseName);
        });

        const trimString = (val) => {
            if (typeof val != 'string' || val.length < 1) {
                return '';
            }
            return val.trim();
        };

        const toggleAll = () => {
            if (allSelected.value) {
                store.commit('clearSelection');
                allSelected.value = false;
            } else {
                propItems.value.forEach(item => {
                    store.commit('toggleSelectionInclusion', item.base_filename);
                });
                allSelected.value = true;
            }
        };

        const edit = ref((event, baseFileName) => {
            //ignore if was the item selector radio
            if (event.target.tagName.toUpperCase() == 'INPUT') {
                return;
            }
            //now using state to pass which basename was clicked to adminedit page
            router.push({
                name: 'AdminEdit',
                state: {
                    edit_basename: baseFileName,
                },
            });
        });

        const deleteSelected = () => {
            context.emit('deleteSelected');
        };

        const addSelected = () => {
            store.commit('addSelectedToCollected');
        };

        const clearCollected = () => {
            store.commit('clearCollected');
        };

        const loadCollected = () => {
            store.commit('clearSelection');
            context.emit('loadCollected');
        };

        return { allSelected, currentSelectionState, toggleSelectedState, trimString, toggleAll, edit, deleteSelected, addSelected, clearCollected, currentCollectedState, loadCollected };
    },
};
</script>

<style lang="scss" scoped>
@import '../sass/_variables.scss';

table {
    width: 100%;

    tr:nth-of-type(even) {
        background-color: #f1f0ff;
    }

    th {
        height: 3em;
        background-color: #ddd;
    }

    th,
    td {
        vertical-align: middle;
        padding-left: 1em;
        padding-right: 1em;
    }

    td {
        cursor: pointer;
    }

    td.thumb {
        img {
            max-height: 50px;
        }
    }

    .centre {
        text-align: center;
    }

    i.dim {
        opacity: 0.3;
    }
}

.listViewControls {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    z-index: 100;
    background-color: #efefef;
    border-top: 1px solid #bbb;

    .controlGrid {
        max-width: 1300px;
        margin: 0 auto;
        padding: 1em 0;
        display: grid;
        grid-template-columns: repeat(6, max-content);
        grid-template-rows: repeat(4, min-content);
        grid-column-gap: 5px;
        grid-row-gap: 5px;
        align-items: center;
    }

    .actionLabel {
        font-size: 0.85em;
        font-weight: 300;
    }

    button {
        background-color: #dad3ef;
        border: none;
        border-radius: 3px;
        padding: 0.8em;

        &:hover {
            background-color: #e3ddf3
        }
    }
}
</style>
