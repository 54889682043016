<template>
  <teleport to="body">
    <div class="msgOverlay" v-if="msg != null && msg != 'WORKING' && msg != 'DONE'">
      <div class="msg message has-background-light">
        <div class="message-header">{{ msg.header }}</div>
        <div class="message-body title is-6" v-html="msg.body"></div>
        <div v-if="msgButtons === null" class="msgOverlay__button">
          <button class="button is-primary" @click="basicClick('ok')">OK</button>
        </div>
        <div v-else class="msgOverlay__button">
          <button v-for="(btn, index) in msgButtons" :key="index" class="button is-primary" @click="storeVal(btn.val)">{{ btn.caption }}</button>
        </div>
        <slot></slot>
      </div>
    </div>
    <div class="msgOverlay" v-if="msg == 'WORKING'">
      <div class="workingBox"><img src="../assets/search-item-placeholder.gif" alt="" /></div>
    </div>
    <div class="msgOverlay" v-if="msg == 'DONE'">
      <div class="doneBox">DONE</div>
    </div>
  </teleport>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { ref } from 'vue';
export default {
  setup() {
    //NOTE add colour options to highlight more serious messages?
    const store = useStore();
    const msg = computed(() => store.getters.getMsg);
    const msgButtons = computed(() => store.getters.getMsgButtons);

    const basicClick = ref((val) => {
      switch (val) {
        case 'ok':
          store.commit('setMsg', null);
          break;
        default:
          store.commit('setMsg', null);
      }
    });

    const storeVal = ref((val) => {
      store.commit('setMsgClickVal', val);
    });

    return { msg, msgButtons, basicClick, storeVal };
  },
};
</script>

<style lang="scss" scoped>
.msgOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;

  .message-body {
    text-align: center;
  }

  &__button {
    text-align: center;
    margin: 1em;

    button:not(:last-of-type) {
      margin-right: 1em;
    }
  }

  .workingBox {
    background-color: #fff;
    padding: 20px 40px;
    border-radius: 20px;
  }

  .doneBox {
    background-color: #0f0;
    color: #000;
    padding: 1em;
    border: 6px solid #fff;
    border-radius: 5px;
  }
}
</style>
