<template>
  <div>
    <div class="loginform">
      <div class="panel">
        <div class="panel-heading">Login</div>
        <div class="panel-block">
          <div class="field">
            <label for="" class="label">Username</label>
            <div class="control">
              <input
                class="input"
                type="text"
                name=""
                autocomplete="username"
                v-model="username"
                v-on:keyup.enter="attemptLogin" />
            </div>
          </div>
          <div class="field">
            <label for="" class="label">Password</label>
            <div class="control">
              <input
                class="input"
                type="password"
                name=""
                autocomplete="current-password"
                v-model="password"
                v-on:keyup.enter="attemptLogin" />
            </div>
          </div>
          <div class="loginform__button">
            <button class="button" @click="attemptLogin">Login</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '../apirequests';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default {
  name: 'LoginPage',
  setup() {
    const username = ref(null);
    const password = ref(null);
    const router = useRouter();
    const store = useStore();

    let compatibilityOk = false;

    onMounted(() => {
      checkCompatibility();
    });

    const checkCompatibility = async () => {
      const result = await testLocalStore();
      if (!result) {
        store.commit('setMsg', {
          header: 'Notice',
          body: 'Your browser does not support features required',
        });
      } else {
        compatibilityOk = true;
      }
    };

    const attemptLogin = ref(() => {
      if (!compatibilityOk) {
        checkCompatibility();
        return false;
      }
      const credentials = {
        username: username.value,
        password: password.value,
      };
      Api.attemptLogin(credentials)
        .then((response) => {
          localStorage.setItem('iethis_token', response.token);
          localStorage.setItem('iethis_admin', response.isAdmin);
          //clear low detailed mode - fringe case of me being admin in low mode then logging out and into public login and still having low detailed mode and no way to turn it off
          store.commit('setLowDetailMode', false);
          router.push({ name: 'Home' });
        })
        .catch((err) => {
          console.log(err);
          store.commit('setMsg', {
            header: 'Notice',
            body: 'Could not login with those credentials',
          });
        });
    });

    return { username, password, attemptLogin };
  },
};

async function testLocalStore() {
  try {
    localStorage.setItem('lm_test', '1');
    const test = localStorage.getItem('lm_test');
    localStorage.removeItem('lm_test');
    return test === '1' ? true : false;
  } catch {
    return false;
  }
}
</script>

<style lang="scss" scoped>
.loginform {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3em 0;

  .panel {
    max-width: 350px;
  }

  .panel-block {
    display: block;
  }

  .field {
    margin: 1em;
    display: block;
  }

  input {
    width: 280px;
  }

  &__button {
    text-align: center;
    padding: 1em;
  }
}
</style>
