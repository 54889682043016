<template>
    <div class="item">
        <div class="image"></div>

        <div class="pid">&nbsp;</div>
        <div class="itemDetails">
            <div class="content">&nbsp;</div>
            <div class="pageDetails">
                <div class="pageDetailsTitle">&nbsp;</div>
                <div class="pageDetailsData">&nbsp;</div>
            </div>
            <div class="pageDetails">
                <div class="pageDetailsTitle">&nbsp;</div>
                <div class="pageDetailsData">&nbsp;</div>
            </div>
            <div class="pageDetails">
                <div class="pageDetailsTitle">&nbsp;</div>
                <div class="pageDetailsData pageDetailsData__category">&nbsp;</div>
            </div>
            <div class="buttonsContainer">
                <div class="linkButtons">
                    <div class="btnGroup">
                        <button class="disabled">&nbsp;</button>
                        <button class="disabled">&nbsp;</button>
                    </div>
                </div>
                <div class="linkButtons">
                    <div class="btnGroup">
                        <button class="disabled">&nbsp;</button>
                        <button class="disabled">&nbsp;</button>
                    </div>
                </div>
                <div v-if="isAdmin" class="adminSection">
                    <div class="visibleIn">&nbsp;</div>
                    <div>
                        <button class="disabled">&nbsp;</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['isAdmin'],
};
</script>

<style lang="scss" scoped>
@import '../sass/_variables.scss';

.item {
    width: 270px;
    margin: 0 auto 15px auto;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 16px;

    .image {
        height: 268px;
        background-size: unset;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url('../assets/search-item-placeholder.gif');
        text-align: center;

        img {
            display: inline-block;
            width: 268px;
            height: 268px;
        }
    }

    .pid {
        background-color: #ddd;
        text-align: center;
        font-size: 1.2em;
        font-weight: 600;
    }

    .itemDetails {
        background-color: #f3f3f3;
        margin-bottom: 1px;

        .content {
            padding: 10px;
            text-align: justify;
            font-size: 0.9em;
            height: 60px;
            overflow: hidden;
        }

        .pageDetails {
            margin: 10px;
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            background-color: #ddd;
            border: 1px solid #ddd;
            border-radius: 5px;

            .pageDetailsTitle {
                background-color: #ddd;
                flex: 0 0 75px;
                padding: 2px 0 2px 3px;
                font-size: 0.9em;
                font-weight: 600;
                line-height: 25px;
                border-radius: 5px 0 0 5px;
            }

            .pageDetailsData {
                text-align: right;
                flex: 0 1 auto;
                padding: 2px 3px 2px 0;
                font-size: 0.9em;
                line-height: 25px;

                &__category {
                    height: 4em;
                    word-break: break-all;
                }
            }
        }

        .buttonsContainer {
            margin: 20px 10px 0 10px;
            padding-bottom: 10px;

            .linkButtons {
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: stretch;
                padding-bottom: 10px;

                .btnGroup {
                    width: 100%;
                    position: relative;
                    display: inline-flex;
                    vertical-align: middle;

                    button {
                        position: relative;
                        flex: 1 1 auto;
                        margin: 0;
                        overflow: hidden;
                        text-transform: none;
                        display: inline-block;
                        font-size: 16px;
                        font-weight: 400;
                        text-align: center;
                        white-space: nowrap;
                        vertical-align: middle;
                        user-select: none;
                        border: 1px solid transparent;
                        padding: 6px 12px;
                        line-height: 1.5;
                        border-radius: 4px;
                        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                        color: #fff;
                        background-color: #335cb7;

                        &:hover {
                            background-color: #0069d9;
                        }

                        &:first-child {
                            margin-left: 0;
                        }

                        &:not(:first-child) {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                            margin-left: -1px;
                            border-left-color: #9bc0ff;

                            &.disabled {
                                border-left-color: #d8d8d8;
                            }
                        }

                        &:not(:last-child) {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }

                        &:not(.disabled) {
                            cursor: pointer;
                        }

                        &.disabled {
                            color: #9ba3ab;
                            background-color: transparent;
                            border: 1px solid #d8d8d8;

                            &:hover {
                                background-color: transparent;
                            }
                        }
                    }
                }
            }

            .adminSection {
                button {
                    width: 100%;
                    user-select: none;
                    border: 1px solid transparent;
                    background-color: $orange;
                    padding: 5px;
                    border-radius: 5px;
                    color: #fff;
                    font-weight: 600;

                    &.disabled {
                        background-color: transparent;
                        border: 1px solid #d8d8d8;

                        &:hover {
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
}
</style>
