<template>
  <teleport to="body">
    <div class="adminBar">
      <div class="container">
        <router-link to="/adminnew"><i class="fas fa-file-upload"></i> Add New Image</router-link>
        <router-link to="/"><i class="fas fa-search"></i> Library</router-link>
        <router-link to="/adminbible"><i class="fas fa-table"></i> Upload Nav XLSX</router-link>
        <a href="#" @click.stop.prevent="loadOrphans"><i class="far fa-file-alt"></i> List Orphans</a>
        <a href="#" @click.stop.prevent="loadInvisible"><i class="fas fa-ghost"></i> List Invisible</a>
      </div>
    </div>
  </teleport>
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
  setup() {
    const router = useRouter();
    const store = useStore();

    const loadOrphans = () => {
      store.commit('setLoadDefined', 'load_orphans');
      if (router.currentRoute.value.name != "Home") {
        router.replace({ name: 'Home' });
      }
    };

    const loadInvisible = () => {
      store.commit('setLoadDefined', 'load_invisible');
      if (router.currentRoute.value.name != "Home") {
        router.replace({ name: 'Home' });
      }
    };

    return { loadOrphans, loadInvisible };
  }
};
</script>

<style lang="scss" scoped>
.adminBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: #777;
  color: #fff;
  padding-top: 5px;

  .container {
    padding: 0 1em;
    font-size: 1.3em;

    a {
      color: #fff;

      &:hover {
        color: #ccc;
      }

      &:not(:first-child) {
        margin-left: 2em;
      }
    }
  }
}
</style>
