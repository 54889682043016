<template>
    <div id="home">
        <div class="homeSearch">
            <input type="text" name="searchTerms" placeholder="Enter Search" v-model="searchTerms" v-on:keyup.enter="loadFromSearch" />
            <select v-if="showSearchOptions" name="limitArea" class="form-control" v-model="limitArea">
                <option value="all" selected="">All Areas</option>
                <option value="i.mapped_code">Product ID Only</option>
                <option value="b.bsl_category1">Category Name Only</option>
                <option value="b.page_no">Page Number Only</option>
            </select>
            <button class="button" @click="loadFromSearch">Go</button>
        </div>
        <div v-if="!itemsShown.length" class="searchHint successBox">Enter Your Text Above and
            <br />Hit Go to Search
        </div>
        <div class="results" v-if="!lowDetailMode && itemsShown.length">
            <ResultItem v-for="(item, index) in itemsShown" :key="index" :itemData="item" :isAdmin="isAdmin" :thisTerms="searchTerms" :thisScope="limitArea"> </ResultItem>
        </div>
        <div class="results" v-if="showSkeleton">
            <ResultItemSkeleton v-for="n in 3" :key="n" :isAdmin="isAdmin"></ResultItemSkeleton>
        </div>
        <div class="results" v-if="lowDetailMode && itemsShown.length">
            <ListResultTable :items="itemsShown" :isAdmin="isAdmin" :thisTerms="searchTerms" :thisScope="limitArea" @deleteSelected="deleteSelected" @loadCollected="loadCollected"> </ListResultTable>
        </div>
        <div class="stats" :class="{ 'extraPad': lowDetailMode && shownCount >= totalItems }" v-if="itemsShown.length">
            <p>Showing {{ shownCount }} of {{ totalItems }}</p>
        </div>
        <div class="loadMore" :class="{ 'extraPad': lowDetailMode }" v-if="shownCount < totalItems">
            <button class="button" @click="addChunkToShown">More...</button>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { computed, inject, onMounted, ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import ResultItem from '../components/ResultItem';
import ResultItemSkeleton from '../components/ResultItemSkeleton';
import ListResultTable from '../components/ListResultTable';
import Api from '../apirequests';
export default {
    name: 'HomePage',
    components: { ResultItem, ResultItemSkeleton, ListResultTable },
    setup() {
        const router = useRouter();
        const store = useStore();
        const currentRoute = useRoute();
        const searchTerms = ref(null);
        const limitArea = ref('all');
        const chunkSize = 20;
        const showChunk = ref(-1);
        let itemsData = [];
        const totalItems = ref(0);
        const itemsShown = ref([]);
        const isAdmin = computed(() => store.getters.getIsAdmin);
        const lowDetailMode = computed(() => store.getters.getLowDetailMode);
        const showSkeleton = ref(false);
        const libraryVariant = isAdmin.value ? 'all' : inject('libraryVariant', 'bsl');
        const showSearchOptions = ref(true);

        onMounted(() => {
            showSearchOptions.value = (libraryVariant == 'bsl' || isAdmin.value) ? true : false;
            ascertainState();
        });

        const ascertainState = () => {
            if (currentRoute.query.hasOwnProperty('iid')) {
                //it is a link to specific image basename (not used internally, offered as a direct to image library link on the Admin pages)
                //to remove the querystring from future naviagations, will set the requested data on the store state and replace the page
                //will then be loaded by below, same as others
                store.commit('setLastTerms', currentRoute.query.iid);
                store.commit('setLastLimitArea', 'i.base_filename');
                router.replace({ name: 'Home' });
            }

            if (store.getters.getLastTerms) {
                searchTerms.value = store.getters.getLastTerms;
                limitArea.value = store.getters.getLastLimitArea;
                loadFromSearch();
            }
        };

        const loadFromSearch = () => {
            if (searchTerms.value == null || searchTerms.value.trim().length < 1) {
                return false;
            }
            const loadArgs = {
                terms: searchTerms.value.trim(),
                scope: buildScope(isAdmin.value, libraryVariant, limitArea.value),
                libraryVariant: libraryVariant,
                exact: false
            };
            //reset last query if it was from an iid direct link - that was a one-off
            if (limitArea.value == 'i.base_filename') {
                searchTerms.value = '';
                limitArea.value = 'all';
            }
            store.commit('setLoadDefined', false);
            store.commit('setLastTerms', searchTerms.value.trim());
            store.commit('setLastLimitArea', store.getters.getLastLimitArea);
            getData('libSearch', loadArgs);
        };

        const loadCollected = () => {
            //emitted from list view
            //specifically load items as defined in collected list(list view only)
            //reset any previous search
            store.commit('setLastTerms', '');
            store.commit('setLastLimitArea', 'all');
            getData('getCollected', store.getters.getCollectedItems);
        };

        const loadDefinedQuery = () => {
            //changes to state made inside this method called from a watcher will not be reflected in the state...
            //so the loadDefined state is set back to false when a different search is started
            const queryName = store.getters.getLoadDefined;
            if (queryName) {
                switch (queryName) {
                    case 'load_orphans':
                        if (isAdmin.value) {
                            loadOrhpans();
                        }
                        break;
                    case 'load_invisible':
                        if (isAdmin.value) {
                            loadInvisible();
                        }
                        break;
                }
            }
        };

        const loadOrhpans = () => {
            //from admin bar
            store.commit('setLowDetailMode', true);
            searchTerms.value = '';
            store.commit('setLastTerms', '');
            store.commit('setLastLimitArea', 'all');
            getData('getOrphans', null);
        };

        const loadInvisible = () => {
            //from admin bar
            store.commit('setLowDetailMode', true);
            searchTerms.value = '';
            store.commit('setLastTerms', '');
            store.commit('setLastLimitArea', 'all');
            getData('getInvisible', null);
        };

        const getData = (endpoint, loadArgs) => {
            itemsShown.value = [];
            showChunk.value = -1;
            showSkeleton.value = true;
            //call the api then display first chunk
            Api[endpoint](loadArgs)
                .then((response) => {
                    if (Array.isArray(response.data) && response.data.length) {
                        totalItems.value = response.data.length;
                        itemsData = _.chunk(response.data, chunkSize);
                        addChunkToShown();
                    } else {
                        showSkeleton.value = false;
                        store.commit('setMsg', {
                            header: 'Nothing Found',
                            body: `No matching results`,
                        });
                    }
                })
                .catch((err) => {
                    handleApiError(err.message);
                });
        };

        const handleApiError = (statusOrError) => {
            switch (statusOrError) {
                case '401' || 401:
                    store.commit('setMsg', {
                        header: 'Notice',
                        body: 'Not Logged In',
                    });
                    router.push({ name: 'Login' });
                    break;
                default:
                    store.commit('setMsg', {
                        header: 'Notice',
                        body: `An error ${statusOrError} occured`,
                    });
            }
        };

        const addChunkToShown = () => {
            //if not initial load - skeleton will not be showing - show
            showSkeleton.value = true;
            showChunk.value++;
            itemsShown.value = itemsShown.value.concat(itemsData[showChunk.value]);
            showSkeleton.value = false;
        };

        const shownCount = computed(() => {
            return itemsShown.value.length;
        });

        const deleteSelected = () => {
            //emitted from list view - selecteds are in state
            //what to show after is a bit unknown - as some currently showing will have been deleted - just using last search
            //has a different callback to getData - so can't really combine
            itemsShown.value = [];
            showChunk.value = -1;
            showSkeleton.value = true;
            Api.deleteCollected(store.getters.getListSelection)
                .then(() => {
                    store.commit('clearSelection');
                    searchTerms.value = store.getters.getLastTerms;
                    limitArea.value = store.getters.getLastLimitArea;
                    loadFromSearch();
                })
                .catch((err) => {
                    handleApiError(err.message);
                });
        };

        /**
         * Watchers
         */

        watchEffect(() => {
            loadDefinedQuery();
        });

        return { showSearchOptions, searchTerms, limitArea, totalItems, itemsShown, isAdmin, lowDetailMode, loadFromSearch, showSkeleton, addChunkToShown, shownCount, libraryVariant, deleteSelected, loadCollected };
    },
};

function buildScope(isAdmin, libraryVariant, limitArea) {
    //only admin and BSL variant have the dropdown to limit search fields
    if (isAdmin) {
        if (limitArea == 'all') {
            return 'i.mapped_code:b.bsl_category1:b.page_no:b.generated_description_combined:b.vendor_item_no';
        }
        if (limitArea == 'i.mapped_code') {
            return 'i.mapped_code:b.vendor_item_no';
        }
        return limitArea;
    }
    if (libraryVariant == 'bsl') {
        if (limitArea == 'all') {
            return 'i.mapped_code:b.bsl_category1:b.page_no:b.generated_description_combined';
        }
        return limitArea;
    }
    if (libraryVariant == 'wsl') {
        return 'i.mapped_code:b.bsl_category1:b.generated_description_combined';
    }
    if (libraryVariant == 'niels') {
        return 'b.vendor_item_no:b.generated_description_combined';
    }
}
</script>

<style lang="scss" scoped>
@import '../sass/_variables.scss';

.homeSearch {
    font-size: 16px;
    display: flex;

    input,
    select {
        display: block;
        width: 100%;
        padding: 0.375em 0.75em;
        font-size: 1em;
        line-height: 1.5;
        color: #495057;
        border: 1px solid #ced4da;
        border-radius: 0.25em;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    select {
        flex: 0 0 14em;
        margin: 0 1em;
    }

    button {
        background-color: $bsl_blue_text;
        color: #fff;
        padding: 0.375em 0.75em;
        min-width: 8em;
        font-size: inherit;
    }
}

.results {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    margin-top: 15px;
}

.stats {
    text-align: center;
    font-size: 1.2em;
    font-weight: 600;
    margin-top: 2.5em;
}

.loadMore {
    margin-top: 1em;
    padding-bottom: 2.5em;
    text-align: center;

    button {
        background-color: #fff;
        color: $bsl_blue_text;
        padding: 0.375em 0.75em;
        min-width: 8em;
        font-size: inherit;
        border-color: $bsl_blue_text;
        font-weight: 600;

        &:focus {
            box-shadow: none;
        }
    }
}

.extraPad {
    padding-bottom: 14em;
}
</style>
