//TODO need to replace each file to use this
const handleApiError = (store, router, statusOrError) => {
  switch (statusOrError) {
    case '401' || 401:
      store.commit('setMsg', {
        header: 'Notice',
        body: 'Not Logged In',
      });
      router.push({ name: 'Login' });
      break;
    default:
      store.commit('setMsg', {
        header: 'Notice',
        body: `An error '${statusOrError}' occured`,
      });
  }
};

export default handleApiError;
