<template>
    <table class="table adminTable bibleDataTable">
        <thead>
            <tr>
                <th>Field</th>
                <th>Value</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Mapping to Code:</td>
                <td>{{ bibleData.item_no }}</td>
            </tr>
            <tr>
                <td>Description 1:</td>
                <td>{{ bibleData.description1 }}</td>
            </tr>
            <tr>
                <td>Description 2:</td>
                <td>{{ bibleData.description2 }}</td>
            </tr>
            <tr>
                <td>Description 3:</td>
                <td>{{ bibleData.description3 }}</td>
            </tr>
            <tr>
                <td>Page No.:</td>
                <td>{{ bibleData.page_no }}</td>
            </tr>
            <tr>
                <td>Category1:</td>
                <td>{{ bibleData.bsl_category1 }}</td>
            </tr>
            <tr>
                <td>Niels Larsen ID:</td>
                <td>{{ bibleData.vendor_item_no }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { ref } from '@vue/reactivity';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Api from '../apirequests';
import { onMounted } from '@vue/runtime-core';
export default {
    name: 'BibleDataTable',
    props: ['pid'],
    setup(props) {
        const store = useStore();
        const router = useRouter();
        const bibleData = ref({});

        onMounted(() => {
            Api.bibleData(props.pid)
                .then((response) => {
                    //need to handle if this id not in bible
                    if (!response.data || typeof response.data != 'object' || !response.data.hasOwnProperty('item_no')) {
                        bibleData.value = {
                            id: 0,
                            item_no: '',
                            description1: '',
                            description2: '',
                            description3: '',
                            page_no: '',
                            bsl_category1: '',
                            vendor_item_no: '',
                            generated_description_combined: '',
                        };
                        return;
                    }
                    bibleData.value = response.data;
                })
                .catch((err) => {
                    handleApiError.value(err.message);
                });
        });

        const handleApiError = ref((statusOrError) => {
            switch (statusOrError) {
                case '401' || 401:
                    store.commit('setMsg', {
                        header: 'Notice',
                        body: 'Not Logged In',
                    });
                    router.push({ name: 'Login' });
                    break;
                default:
                    store.commit('setMsg', {
                        header: 'Notice',
                        body: `An error ${statusOrError} occured`,
                    });
            }
        });

        return { bibleData };
    },
};
</script>

<style lang="scss" scoped>
.bibleDataTable {
    margin: 0 auto;
    margin-bottom: 100px;
    width: 80%;
    text-align: left;

    thead tr {
        background-color: #d2d2d2;

        th {
            border-bottom: #868686 2px solid;
            padding: auto 1em;

            &:not(:last-of-type) {
                border-right: #868686 1px solid;
            }
        }

        th:first-of-type {
            text-align: center;
        }
    }

    td:first-of-type {
        background-color: #f3f3f3;
        text-align: right;
        font-weight: 600;
    }

    td:not(:last-of-type) {
        border-right: #868686 1px solid;
    }
}
</style>
