<template>
    <div class="imageUpload">
        <div class="radiosCenter">
            <label for="">Generate All From Tiff</label>
            <input type="radio" name="" id="" value="gentiff" v-model="uploadMode" />
            <label for="">Upload Each File</label>
            <input type="radio" name="" id="" value="upall" v-model="uploadMode" />
        </div>
        <div class="generateFromTiff" v-if="uploadMode == 'gentiff'">
            <div class="file">
                <div class="flexCentred" v-if="isEdit">
                    <span>Has Tiff?</span>
                    <i class="fas fa-check-circle has-text-success" v-if="itemData.has_tiff"></i>
                    <i class="fas fa-times-circle" v-else></i>
                </div>
                <div class="flexCentred" v-else>
                    <span>Required</span>
                </div>
                <div class="flexCentred">
                    <span>
                        Upload
                    </span>
                </div>
                <label v-show="variantActions.tiff == null" class="file-label">
                    <input class="file-input" type="file" name="" data-filetype="tiff" @change="fileSelected($event)" />
                    <span class="file-cta">
                        <span class="file-icon">
                            <i class="fas fa-upload"></i>
                        </span>
                        <span class="file-label">
                            Choose…
                        </span>
                    </span>
                </label>
                <div v-show="variantActions.tiff != null" class="flexCentred has-text-warning">
                    <span>
                        {{ variantActions.tiff }}
                    </span>
                </div>
                <img src="/staticimages/admin_file_icons/tiff_active.png" />
                <div class="flexCentred rowIcon">
                    <i class="fas fa-undo-alt" @click="undoSelection('tiff')"></i>
                </div>
                <div v-if="isEdit" class="flexCentred rowIcon">
                    <i class="far fa-trash-alt" @click="removeVariant('tiff')"></i>
                </div>
            </div>
        </div>
        <div class="uploadEach" v-else>
            <div class="file">
                <div class="flexCentred" v-if="!isEdit">
                    <span>Required</span>
                </div>
                <div class="flexCentred">
                    <span>
                        Upload
                    </span>
                </div>
                <label v-show="variantActions.jpglo == null" class="file-label">
                    <input class="file-input" type="file" name="" data-filetype="jpglo" @change="fileSelected($event)" />
                    <span class="file-cta">
                        <span class="file-icon">
                            <i class="fas fa-upload"></i>
                        </span>
                        <span class="file-label">
                            Choose…
                        </span>
                    </span>
                </label>
                <div v-show="variantActions.jpglo != null" class="flexCentred has-text-warning">
                    <span>
                        {{ variantActions.jpglo }}
                    </span>
                </div>
                <img src="/staticimages/admin_file_icons/jpg_small_active.png" />
                <div class="flexCentred rowIcon">
                    <i class="fas fa-undo-alt" @click="undoSelection('jpglo')"></i>
                </div>
                <div v-if="isEdit" class="flexCentred rowIcon" style="opacity:0;cursor:default">
                    <i class="far fa-trash-alt"></i>
                </div>
            </div>

            <div class="file">
                <div class="flexCentred" v-if="isEdit">
                    <span>Has High Res JPG?</span>
                    <i class="fas fa-check-circle has-text-success" v-if="itemData.has_jpeg_high"></i>
                    <i class="fas fa-times-circle" v-else></i>
                </div>
                <div class="flexCentred">
                    <span>
                        Upload
                    </span>
                </div>
                <label v-show="variantActions.jpghigh == null" class="file-label">
                    <input class="file-input" type="file" name="" data-filetype="jpghigh" @change="fileSelected($event)" />
                    <span class="file-cta">
                        <span class="file-icon">
                            <i class="fas fa-upload"></i>
                        </span>
                        <span class="file-label">
                            Choose…
                        </span>
                    </span>
                </label>
                <div v-show="variantActions.jpghigh != null" class="flexCentred has-text-warning">
                    <span>
                        {{ variantActions.jpghigh }}
                    </span>
                </div>
                <img src="/staticimages/admin_file_icons/jpg_large_active.png" />
                <div class="flexCentred rowIcon">
                    <i class="fas fa-undo-alt" @click="undoSelection('jpghigh')"></i>
                </div>
                <div v-if="isEdit" class="flexCentred rowIcon">
                    <i class="far fa-trash-alt" @click="removeVariant('jpghigh')"></i>
                </div>
            </div>

            <div class="file">
                <div class="flexCentred" v-if="isEdit">
                    <span>Has Tiff?</span>
                    <i class="fas fa-check-circle has-text-success" v-if="itemData.has_tiff"></i>
                    <i class="fas fa-times-circle" v-else></i>
                </div>
                <div class="flexCentred">
                    <span>
                        Upload
                    </span>
                </div>
                <label v-show="variantActions.tiff == null" class="file-label">
                    <input class="file-input" type="file" name="" data-filetype="tiff" @change="fileSelected($event)" />
                    <span class="file-cta">
                        <span class="file-icon">
                            <i class="fas fa-upload"></i>
                        </span>
                        <span class="file-label">
                            Choose…
                        </span>
                    </span>
                </label>
                <div v-show="variantActions.tiff != null" class="flexCentred has-text-warning">
                    <span>
                        {{ variantActions.tiff }}
                    </span>
                </div>
                <img src="/staticimages/admin_file_icons/tiff_active.png" />
                <div class="flexCentred rowIcon">
                    <i class="fas fa-undo-alt" @click="undoSelection('tiff')"></i>
                </div>
                <div v-if="isEdit" class="flexCentred rowIcon">
                    <i class="far fa-trash-alt" @click="removeVariant('tiff')"></i>
                </div>
            </div>
        </div>

        <hr />

        <div class="file">
            <div class="flexCentred" v-if="isEdit">
                <span>Has PDF?</span>
                <i class="fas fa-check-circle has-text-success" v-if="itemData.has_pdf"></i>
                <i class="fas fa-times-circle" v-else></i>
            </div>
            <div class="flexCentred">
                <span>
                    Upload
                </span>
            </div>
            <label v-show="variantActions.pdf == null" class="file-label">
                <input class="file-input" type="file" name="" data-filetype="pdf" @change="fileSelected($event)" />
                <span class="file-cta">
                    <span class="file-icon">
                        <i class="fas fa-upload"></i>
                    </span>
                    <span class="file-label">
                        Choose…
                    </span>
                </span>
            </label>
            <div v-show="variantActions.pdf != null" class="flexCentred has-text-warning">
                <span>
                    {{ variantActions.pdf }}
                </span>
            </div>
            <img src="/staticimages/admin_file_icons/pdf_active.png" />
            <div class="flexCentred rowIcon">
                <i class="fas fa-undo-alt" @click="undoSelection('pdf')"></i>
            </div>
            <div v-if="isEdit" class="flexCentred rowIcon">
                <i class="far fa-trash-alt" @click="removeVariant('pdf')"></i>
            </div>
        </div>

        <div class="companyCheckBoxes">
            <label for="">Show On BSL Library</label>
            <input type="checkbox" name="" id="" v-model="showBSL" />
            <label for="">Show On WSL Library</label>
            <input type="checkbox" name="" id="" v-model="showWSL" />
        </div>
        <div class="actionButtons">
            <button class="button is-info is-medium" @click.prevent="backToLastSearch">Back</button>
            <button v-if="isEdit" class="button is-danger is-medium" @click.prevent="removeSetClick">Remove Image Set</button>
            <button class="button is-success is-medium" @click.prevent="saveClick">Save</button>
        </div>
        <div class="directLinks">
            <div>
                <a :href="`http://imagelibrary.bishopsport.co.uk/?iid=${itemData.base_filename}`">imagelibrary.bishopsport.co.uk/?iid={{ itemData.base_filename }}</a>
            </div>
            <div>
                <a :href="`http://imagelibrary.wilkinsonsport.co.uk/?iid=${itemData.base_filename}`">imagelibrary.wilkinsonsport.co.uk/?iid={{ itemData.base_filename }}</a>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, toRef, toRefs } from '@vue/reactivity';
import { useStore } from 'vuex';
import Api from '../apirequests';
import { computed, watch } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
export default {
    name: 'FileUpload',
    props: ['isEdit', 'itemData'],
    emits: ['actionCompleted'],
    setup(props, context) {
        const store = useStore();
        const router = useRouter();
        const isEdit = toRef(props, 'isEdit');
        const { base_filename, mapped_code, show_bsl, show_wsl } = toRefs(props.itemData);
        const formData = ref(new FormData());
        const uploadMode = ref('gentiff');
        const maxUploadMb = 30;
        const maxUploadBytes = 31457280;
        const variantActions = ref({
            jpglo: null,
            jpghigh: null,
            tiff: null,
            pdf: null,
        });
        const variantSizes = {
            jpglo: 0,
            jpghigh: 0,
            tiff: 0,
            pdf: 0,
        };
        const showBSL = ref(show_bsl.value ? true : false);
        const showWSL = ref(show_wsl.value ? true : false);

        //watch the msgClick in store for changes - user reacted to message box
        const msgClickVal = computed(() => store.getters.getMsgClickVal);
        watch(msgClickVal, () => {
            if (store.getters.getMsgOwner == 'fileUpload' && msgClickVal.value != null) {
                //might as well reset the owner here - will get set each time anyway
                store.commit('setMsgOwner', null);
                hanldeMsgClick(msgClickVal.value);
            }
        });

        const fileSelected = ref((event) => {
            if (typeof event.target.files[0].name != 'string') {
                return;
            }
            const inputTags = ['jpglo', 'jpghigh', 'tiff', 'pdf'];
            const requiredExts = ['jpg', 'jpg', 'tif', 'pdf'];
            const requiredTypes = ['image/jpeg', 'image/jpeg', 'image/tiff', 'application/pdf'];
            const checkIndex = inputTags.indexOf(event.target.dataset.filetype);
            if (checkIndex < 0) {
                //have to clear the filesList on the input as it still has it - so no future change events will fire
                event.target.value = '';
                return false;
            }
            const result = checkFileSelected(event.target.files[0].name, event.target.files[0].type, event.target.files[0].size, maxUploadBytes, maxUploadMb, base_filename.value, requiredExts[checkIndex], requiredTypes[checkIndex]);
            if (result !== true) {
                //have to clear the filesList on the input as it still has it - so no future change events will fire
                event.target.value = '';
                store.commit('setMsg', {
                    header: 'Notice',
                    body: result,
                });
                return;
            }
            //for tracking changes to the file selection status - not changed (null), new file (filename), removal ('REMOVE')
            variantActions.value[event.target.dataset.filetype] = event.target.files[0].name;
            variantSizes[event.target.dataset.filetype] = event.target.files[0].size >= 1048576 ? Math.round(event.target.files[0].size / 1048576) : 1;
            //appending to formData will not replace previous with same field name - delete it first
            formData.value.delete(event.target.dataset.filetype);
            //add the selected file to the formData to be uploaded
            formData.value.append(event.target.dataset.filetype, event.target.files[0]);
        });

        const undoSelection = ref((filetype) => {
            variantActions.value[filetype] = null;
            variantSizes[filetype] = 0;
            formData.value.delete(filetype);
            //have to clear the filesList on the input as it still has it - so no future change events will fire
            const fileInput = document.querySelector(`input[data-filetype="${filetype}"]`);
            if (fileInput) {
                fileInput.value = '';
            }
        });

        const removeVariant = ref((filetype) => {
            //same as undo but set the status to remove for sending to server
            undoSelection.value(filetype);
            variantActions.value[filetype] = 'REMOVE';
            variantSizes[filetype] = 0;
        });

        const backToLastSearch = ref(() => {
            router.push({
                name: 'Home',
            });
        });

        const removeSetClick = ref(() => {
            store.commit('setMsgOwner', 'fileUpload');
            store.commit('setMsgButtons', [
                { caption: 'Cancel', val: 'removeSetCancel' },
                { caption: 'Continue', val: 'removeSetOk' },
            ]);
            store.commit('setMsg', {
                header: 'Warning',
                body: `This will remove all files with name ${base_filename.value}<br>They will no longer be searchable`,
            });
        });

        const saveClick = ref(() => {
            //if it is a new image set - must have jpg low at least - or a tiff to generate all from
            if (isEdit.value == false) {
                if (uploadMode.value == 'gentiff' && variantActions.value.tiff == null) {
                    store.commit('setMsg', {
                        header: 'Issue',
                        body: `You must select a Tiff File to Upload`,
                    });
                    return;
                }
                if (uploadMode.value != 'gentiff' && variantActions.value.jpglo == null) {
                    store.commit('setMsg', {
                        header: 'Issue',
                        body: `You must select a JPG for the low res option`,
                    });
                    return;
                }
            }

            //check size of all uploads is less than limit
            let total = 0;
            let msgText = '';
            Object.keys(variantSizes).forEach((type) => {
                if (typeof variantSizes[type] == 'number' && variantSizes[type] > 0) {
                    total += variantSizes[type];
                    msgText += `${type} = ${variantSizes[type]}MB<br>`;
                }
            });
            if (total > maxUploadMb) {
                store.commit('setMsg', {
                    header: 'Issue',
                    body: `Total size of uploads is too big. Max is ${maxUploadMb}MB for all.<br><br> ${msgText}`,
                });
                return;
            }
            //all good
            actionSave();
        });

        const hanldeMsgClick = (msgClickVal) => {
            store.commit('setMsgButtons', null);
            store.commit('setMsgClickVal', null);
            switch (msgClickVal) {
                case 'removeSetOk':
                    //ok to removeImageSet
                    actionRemoveSet();
                    break;
                default:
                    store.commit('setMsg', null);
            }
        };

        const actionRemoveSet = () => {
            prepTextData('isedit', isEdit.value);
            prepTextData('basename', base_filename.value);
            prepTextData('variantActions', 'ignore');
            prepTextData('removeset', true);
            doApi('remove');
        };

        const actionSave = () => {
            //adding non file text data - delete first as appending does not replace
            prepTextData('isedit', isEdit.value);
            prepTextData('basename', base_filename.value);
            prepTextData('mapped_code', mapped_code.value);
            prepTextData('uploadmode', uploadMode.value);
            prepTextData('variantActions', JSON.stringify(variantActions.value));
            prepTextData('showbsl', showBSL.value);
            prepTextData('showwsl', showWSL.value);
            prepTextData('removeset', false);
            doApi('upload');
        };

        const prepTextData = (field, value) => {
            formData.value.delete(field);
            formData.value.append(field, value);
        };

        const doApi = (actionType) => {
            /* for (let i of formData.value.entries()) {
                console.log(i);
            } */
            store.commit('setMsg', 'WORKING');
            Api.uploadImageData(formData.value)
                .then(() => {
                    //if all OK - API response is just a status of OK
                    if (actionType == 'upload') {
                        store.commit('setMsg', 'DONE');
                        setTimeout(() => {
                            store.commit('setMsg', null);
                            actionCompleted();
                        }, 2000);
                    }
                    if (actionType == 'remove') {
                        store.commit('setMsg', {
                            header: 'Success',
                            body: `Image Set Removed`,
                        });
                        backToLastSearch.value();
                    }
                })
                .catch((err) => {
                    handleApiError.value(err.message);
                });
        };

        const handleApiError = ref((statusOrError) => {
            switch (statusOrError) {
                case '401' || 401:
                    store.commit('setMsg', {
                        header: 'Notice',
                        body: 'Not Logged In',
                    });
                    router.push({ name: 'Login' });
                    break;
                default:
                    store.commit('setMsg', {
                        header: 'Notice',
                        body: `An error '${statusOrError}' occured`,
                    });
            }
        });

        const actionCompleted = () => {
            //clear all image inputs
            const variantSizeNames = Object.keys(variantSizes);
            variantSizeNames.forEach((size) => {
                undoSelection.value(size);
            });
            context.emit('actionCompleted');
        };

        return { uploadMode, variantActions, fileSelected, undoSelection, removeVariant, backToLastSearch, removeSetClick, saveClick, showBSL, showWSL };
    },
};

const checkFileSelected = (fileName, fileType, fileSize, maxUploadBytes, maxUploadMb, baseName, requiredExt, requiredType) => {
    console.log(fileSize);
    const fileNameParts = fileName.split('.');
    const fileExt = fileNameParts.pop();
    const fileBaseName = fileNameParts.join('.');
    if (fileExt != requiredExt || fileType != requiredType) {
        return `Incorrect file type or extension. Should be ${requiredExt}`;
    }
    if (fileBaseName != baseName) {
        return `Incorrect file name. Should be ${baseName}.${requiredExt}`;
    }
    if (fileSize > maxUploadBytes) {
        return `File Too Big. Size is ${Math.round(fileSize / 1048576)}MB - Max possible is ${maxUploadMb}MB`;
    }
    return true;
};
</script>

<style lang="scss" scoped>
@import '../sass/_variables.scss';

.imageUpload {
    min-width: 300px;
    width: 60%;
    margin: 0 auto;

    .flexCentred {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        span {
            font-size: 1em;
            line-height: 1em;
            font-weight: 600;
        }

        i {
            font-size: 1.4em;
            margin: 0 5px;
        }
    }

    .radiosCenter {
        font-size: 1.2em;
        font-weight: 600;
        text-align: center;
        margin: 0 auto 2em auto;

        label {
            margin-right: 0.3em;

            &:not(:first-of-type) {
                margin-left: 3em;
            }
        }

        input {
            width: 16px;
            height: 16px;
            vertical-align: middle;
        }
    }

    .file {
        justify-content: flex-end;
        margin-right: 10%;
        margin-bottom: 3em;

        .flexCentred {
            &:not(:last-of-type) {
                margin-right: 1em;
            }
        }

        img {
            margin-left: 1em;
        }

        .file-cta .file-label {
            display: none;

            @media (min-width: 1000px) {
                display: flex;
            }
        }

        .rowIcon {
            margin-left: 2em;
            cursor: pointer;
        }
    }

    .companyCheckBoxes {
        font-size: 1.2em;
        font-weight: 600;
        text-align: center;
        margin-top: 4em;

        label {
            margin-right: 0.3em;

            &:not(:first-of-type) {
                margin-left: 3em;
            }
        }

        input {
            width: 16px;
            height: 16px;
            vertical-align: middle;
        }
    }
}

.actionButtons {
    margin-top: 3em;
    text-align: center;

    button:not(:first-of-type),
    button:not(:last-of-type) {
        margin: auto 2em;
    }
}

.directLinks {
    margin-top: 2em;
    text-align: center;

    a {
        font-size: 1.4em;
        color: $primary;
        text-decoration: underline;
    }
}
</style>
