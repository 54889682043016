import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import AdminNew from '../views/AdminNew.vue';
import AdminEdit from '../views/AdminEdit.vue';
import AdminBible from '../views/AdminBibleUpload.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/adminnew',
        name: 'AdminNew',
        component: AdminNew,
    },
    {
        path: '/adminedit',
        name: 'AdminEdit',
        component: AdminEdit,
    },
    {
        path: '/adminbible',
        name: 'AdminBible',
        component: AdminBible,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return { top: 0 };
    },
});

router.beforeEach(async (to) => {
    if (to.name == 'Login') return true;
    //reset store - in case switching users from one with admin to one without
    store.commit('setHasToken', false);
    store.commit('setIsAdmin', false);
    if (localStorage.getItem('iethis_token') != null) {
        store.commit('setHasToken', true);
    }
    if (localStorage.getItem('iethis_admin') === 'true') {
        store.commit('setIsAdmin', true);
    }
    if (store.getters.getHasToken && to.name == 'Home') return true;
    if (store.getters.getIsAdmin) return true;

    return '/login';
});

export default router;
