<template>
  <table class="table adminTable">
    <thead>
      <tr>
        <th>Thumbnail</th>
        <th>Base Name</th>
        <th class="tac">Variants</th>
        <th class="tac">Image Index</th>
        <th>&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in itemDataExisting" :key="index">
        <td>
          <img :src="`/staticimages/imagethumbs/${item.jpg_filename}`" />
        </td>
        <td>{{ item.base_filename }}</td>
        <td class="variants">
          <img src="/staticimages/admin_file_icons/jpg_small_active.png" alt="" />
          <img :src="variantIcon('jpg_large', item.has_jpeg_high)" alt="" />
          <img :src="variantIcon('tiff', item.has_tiff)" alt="" />
          <img :src="variantIcon('pdf', item.has_pdf)" alt="" />
        </td>
        <td class="fw tac">
          {{ imageindex(item.base_filename) }}
        </td>
        <td class="fw tac"><i class="far fa-edit" @click="edit(item.base_filename)"></i></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
//props in an array of existing product objects with all their data
export default {
  name: 'ExistProdTbl',
  props: ['itemDataExisting'],
  emits: ['lastIndexFound'],
  setup() {
    const router = useRouter();

    const variantIcon = ref((iconName, hasVariant) => {
      if (hasVariant) {
        return `/staticimages/admin_file_icons/${iconName}_active.png`;
      } else {
        return `/staticimages/admin_file_icons/${iconName}.png`;
      }
    });
    const imageindex = ref((basename) => {
      const matches = basename.match(/\[(\d+)\]$/);
      if (Array.isArray(matches) && matches.length > 1 && !isNaN(matches[1])) {
        return matches[1];
      } else {
        return '-';
      }
    });

    const edit = ref((basename) => {
      router.push({
        name: 'AdminEdit',
        state: {
          edit_basename: basename,
        },
      });
    });

    return { variantIcon, imageindex, edit };
  },
};
</script>

<style lang="scss" scoped>
@import '../sass/_variables.scss';

table {
  width: 80%;
  margin: 0 auto;

  tr {
    td {
      &:first-of-type {
        text-align: center;
        width: 110px;
      }

      &.tac {
        text-align: center;
      }

      &.fw {
        width: 8em;
      }

      &.variants {
        text-align: center;
        width: 14em;

        img {
          margin: 0 0.3em;
        }
      }
    }

    img {
      object-fit: contain;
    }
  }

  i {
    margin: 0 0.5em;
    cursor: pointer;
    font-size: 1.3em;
  }
}
</style>
